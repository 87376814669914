<template>
    <div style="width:96%; margin-left:2%">
        <div class="good_img">
            <div class="ml300" style="width: 40%;">
                <div style="margin-top: 70px">
                    <div class="img1">
                        <img class="img1" :src="this.imgCheck">
                    </div>
                </div>
                <div class="detial_img">
                    <img class="img2" :src="tableData.img" @click="imgCheck0">
                    <img class="img2_ml16" :src="tableData.img1" @click="imgCheck1">
                    <img class="img2_ml16" :src="tableData.img2" @click="imgCheck2">
                    <img class="img2_ml16" :src="tableData.img3" @click="imgCheck3">
                    <img class="img2_ml16" :src="tableData.img4" @click="imgCheck4">
                    <img class="img2_ml16" :src="tableData.img5" @click="imgCheck5">
                </div>
            </div>
            <div style="width: 50% margin-left: 10%">
                <div class="title1">
                    <span class="detial_a1">{{ tableData.name }}</span><br>
                    <!--<span class="detial_a1">{{ tableData.types }}</span>-->
                </div>
                <div class="title2">
                    <span>${{ tableData.price }}</span>
                </div>
                <div class="w600" style="text-align: center">
                    <el-button class="detial_button" type="primary"  @click="buyInsurance(tableData.id)">ADD TO CART</el-button><br>
                    <span class="detial_a2">Free shipping worldwide on orders over $100.</span>
                </div>
            </div>
        </div>
        <div style="margin-top: 100px">
            <div style="display: flex; height: 200px">
                <div style="width: 50%;">
                    <span style="font-size: 22px; font-weight: bold">ABOUT THIS PRODUCT</span>
                    <div style="margin-top: 10px">
                        <span class="detial_a3">{{ tableData.name }}</span><br>
                        <!--<span class="detial_a3">{{ tableData.types }}</span>-->
                    </div>
                </div>
                <div style="width: 50%">
                    <div style="height: 25px;margin-bottom: 20px; border-bottom:1px solid #000">
                        <span style="font-size: 15px">SYMBOL</span>
                    </div>
                    <span class="detial_a3">{{ tableData.types }}</span>
                </div>
            </div>
            <div style="display: flex; margin-top: 100px">
                <div style="width: 25%; margin-top: 30px">
                    <div style="margin-bottom: 15px">
                        <span style="font-size: 30px">LEARN MORE</span>
                    </div>
                        <p class="detial_a4">You can learn how to use it and explore its practical applications in different scenarios, enabling you to maximize its use.</p>
                </div>
                <div style="width: 70%; margin-left: 5%; display: flex">
                    <div class="video">
                        <video :src="tableData.video1" style="object-fit:cover" width="100%" height="100%" controls>
                        </video>
                    </div>
                    <div class="video">
                        <video :src="tableData.video2" style="object-fit:cover" width="100%" height="100%" controls>
                        </video>
                    </div>
                    <div class="video">
                        <video :src="tableData.video3" style="object-fit:cover" width="100%" height="100%" controls>
                        </video>
                    </div>
                    
                </div>
            </div>
        </div>
        <div style="height: 100px"></div>

        <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false" :destroy-on-close="true">
            <el-container>
                <el-header>
                    <div style="border-bottom:2px solid #000">
                        <span class="drawer_title">MY LINGFU</span>
                    </div>
                </el-header>
                <el-main>
                    <div>
                        <el-card v-for="item in tableData1" :key="item.id" class="drawer_card" :body-style="{ padding: '5px' }" shadow="hover">
                            <div style="display: flex">
                                <div style="width: 40%">
                                    <img class="drawer_img" :src="item.img">
                                </div>
                                <div style="width:60%; display:flex">
                                    <div style="width: 50%">
                                        <div style="margin-top: 10px; height: 20px">
                                            <span class="drawer_name">{{ item.name }}</span>
                                            <div style="display: flex">
                                                <div style="margin-top: 110px; height: 20px">
                                                    <span class="drawer_name">${{ item.total }}</span>
                                                </div>
                                                <div style="margin-top: 115px; height: 20px;margin-left: 50%">
                                                    <span style="font-size: 15px; color:rgb(95, 95, 95); cursor: pointer" v-if="item.state== '已支付'" @click="refundOrder(item)">退款</span>
                                                    <span style="font-size: 15px; color:rgb(95, 95, 95); cursor: pointer" v-if="item.state== '已退款'" @click="refundsGet(item)">详情</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!--<div style="margin-top: 110px; height: 10px">
                                            <el-input-number size="mini" v-model="num" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number>
                                        </div>-->
                                    </div>
                                    <div style="width: 50%; text-align: right">
                                        <div style="margin-top: 10px; height: 20px">
                                            <i style="font-size: 25px" class="el-icon-close" v-if="item.state == '待支付'" @click="del(item.id)"></i>
                                        </div>
                                        <div style="margin-top: 90px; margin-right: 5%">
                                            <el-button class="drawer_button" type="primary" v-if="item.state== '待支付'" @click="payPal(item)">GET</el-button>
                                            <el-button class="drawer_button" type="primary" v-if="item.state== '已支付'">DETIAL</el-button>
                                            <el-button class="drawer_button" type="primary" v-if="item.state== '已退款'">REBUY</el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-main>
                <el-footer height="100px">
                </el-footer>
            </el-container>
        </el-drawer>

        <!-- 购物车按钮 -->
        <div class="cart">
            <el-button style="
            width: 60px; 
            height: 60px; 
            background-color: #310052;
            border: 1px sloa #fff;
            border-style: double;" 
            size="big" 
            circle
            @click="openDrawer()"
            >
                <i class="el-icon-shopping-cart-1" style="font-size: 28px; color: #fff"></i>
            </el-button>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            tableData: [],
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
            drawer: false ,
            num: 1,
            tableData1: [],
            imgCheck:'',
        }
    },
    created() {
       this.load()
    },
    methods: {
       load() {
            this.request.get("/insurance/" + this.$route.query.id).then(res => {
                this.tableData = res.data
                this.imgCheck = res.data.img
                console.log(this.tableData);
            })
        },
        buyInsurance(insuranceId){
            this.openDrawer();
            if (this.user.trueName === null || this.user.trueId === null || this.user.trueName === "" || this.user.trueId === "") {
                this.$message.error("请先前往实名")
            }else {
                this.request.post('/orders/' + insuranceId).then(res => {
                    if (res.code === '200') {
                        this.$notify({
                            title: '成功加入订单',
                            message: '请前往支付',
                            type: 'success',
                            position: 'top-left'
                        });
                        this.addOrders();
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        addOrders() {
            this.request.get("/orders/getOrderInsurance", {
                params: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    state: '待支付'
                }
            }).then(res => {
                this.tableData1 = res.data
            })
        },
        openDrawer() {
            this.addOrders();
            this.drawer = true;
        },
        del(id) {
            this.request.delete("/orders/" + id).then(res => {
                if (res.code === '200') {
                    this.$message.success("取消成功")
                    this.addOrders();
                } else {
                    this.$message.error("删除失败")
                }
            })
        },
        handleChange(value) {
            console.log(value);
        },
        addOrders() {
            this.request.get("/orders/getOrderInsurance", {
                params: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    state: '待支付'
                }
            }).then(res => {
                this.tableData1 = res.data
            })
        },
        imgCheck0(){
            this.imgCheck = this.tableData.img
        },
        imgCheck1(){
            this.imgCheck = this.tableData.img1
        },
        imgCheck2(){
            this.imgCheck = this.tableData.img2
        },
        imgCheck3(){
            this.imgCheck = this.tableData.img3
        },
        imgCheck4(){
            this.imgCheck = this.tableData.img4
        },
        imgCheck5(){
            this.imgCheck = this.tableData.img5
        },
    }
}
</script>

<style scoped>
.good_img {
    display: flex; 
    height: 840px; 
    border-bottom:2px solid #000;
}
.img1 {
    background-color: black; 
    width:600px; 
    height:600px;
}
.img2 {
    background-color: black; 
    width:14%; 
    height: 80px;
}
.img2_ml16 {
    background-color: black; 
    width:14%; 
    height: 80px;
    margin-left: 4%;
}
.title1 {
    margin-top: 200px;
}
.detial_a1 {
    font-size: 60px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 3px;
}
.detial_img {
    width: 600px; 
    margin-top: 10px; 
    display: flex;
}
.title2 {
    font-size: 45px;
    letter-spacing: 2px;
    margin-top: 50px;
}
.detial_a2 {
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 1px;
}
.detial_button {
    width: 650px;
    height: 60px;
    background-color: #310052;
    color: #fff;
    font-size: 37px;
    letter-spacing: 1px;
    word-spacing: 10px;
    margin-top: 60px;
    margin-bottom: 20px;
    border-radius: 3px;
    border: #fff;
}
.detial_a3 {
    font-size: 60px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 3px;
}
.detial_a4 {
    font-size: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    word-spacing: 4px;
    letter-spacing: 2px;
    line-height: 31px;
}
.video {
    width: 450px;
    height: 400px;
    background-color: #000;
    margin-left: 10px
}
.el-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
}
.el-header {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.drawer_title {
    font-size: 30px;
}
.drawer_card {
    margin-top: 20px;
    margin-left: 1%;
    width: 98%;
    height: 190px;
    border: none;
    border-bottom: rgb(219, 219, 219) 1px solid;
    border-radius: 0;
}
.drawer_img {
    width: 170px;
}
.drawer_name {
    font-size: 20px;
}
.el-footer {

}
.drawer_button {
    width: 80%;
    height: 35px;
    margin-top: 15px;
    font-size: 17px;
    background-color: #310052;
}
.cart {
    position: fixed;
    bottom: 50px;
    right: 100px;
    z-index: 0;
}
.ml300 {
    margin-left: 300px;
}
.w600 {
    width:650px;
}
</style>
